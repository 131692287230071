.booking-card__title {
  button {
    width: 57px;
    height: 24px;
    border-radius: 3px;
    border: solid 1px #a1a1a1;
    background-color: #f9f9f9;
  }
}
.booking-card-wrapper {
  margin: 0 20px 30px 20px;
  padding-bottom: 10px;
  .booking-card-long_haul {
    background-color: #726132 !important;
  }
  .booking-card-now {
    background-color: #3fae29 !important;
  }
  .booking-card-full_day {
    background-color: #7521d3 !important;
  }
  overflow: auto;
  display: flex;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #d8f0d1;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #02751a;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #02751a;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #02751a;
  }
  .booking-card-container {
    position: relative;
    box-sizing: border-box;
    width: 380px;
    min-width: 380px;
    border-radius: 5px;
    background: #00adf5;
    color: #fff;
    margin-right: 20px;
    padding: 15px 20px 20px 20px;
    height: 100%;
    .Closure-List__transit-time {
      background: none;
      .Closure-List-Table-Row {
        display: flex;
        padding-left: 0;
        justify-content: space-between;
        .Left,
        .Right {
          width: unset;
        }
        .Left p {
          padding-right: 0;
        }
        .Right p {
          text-align: right;
          font-weight: 500;
        }
      }
    }
    .recent-card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(100% - 70px);
      .booking-card__info .Closure-List__transit-time .Closure-List-Table-Row{
        margin-top: -3px;
        padding: 0;
        margin-bottom: 10px;
      }
    }
    > div {
      img {
        width: 40px;
        height: 40px;
        margin-right: 10px;
      }
    }
    .booking-card__header {
      div:first-child {
        color: #ffdb00;
        font-size: 18px;
        font-weight: 500;
      }
      div:last-child {
        color: #ffdb00;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .booking-card__status {
      justify-content: space-between;
      white-space: nowrap;
      font-size: 16px;
      span {
        white-space: pre-wrap;
        line-height: 1.37;
        font-weight: 500;
        text-align: right;
      }
      > div:first-child {
        font-size: 16px;
        color: #fff;
      }
      > div:last-child {
        color: #3fae29;
        font-size: 16px;
        font-weight: 500;
        width: 103px;
        box-sizing: border-box;
        height: 25px;
        padding: 3px 6px;
        border-radius: 5px;
        background-color: #fff;
      }
    }
    .booking-card__info {
      p {
        margin-top: 0;
      }
      .driver-info {
        display: flex;
        p {
          margin-top: 0;
          font-size: 12px;
          font-weight: normal;
          padding-top: 5px;
          line-height: 1.37;
        }
        span {
          font-size: 14px;
          color: #fff;
          font-weight: bold;
        }
        img {
          width: 40px;
          height: 40px;
          border-radius: 2px;
          margin-right: 10px;
        }
      }
      .tracking-content-status-vessel {
        p {
          font-size: 14px;
        }
        img {
          width: 16px;
          height: 16px;
        }
      }
      .tracking-content-depot {
        p {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
    hr {
      background-color: #fff;
      margin: 15px 0 13px 0;
      width: calc(100% + 39px);
      position: relative;
      left: -20px;
    }
    .booking-card-action {
      margin-top: 10px;
      .booking-card__button {
        button {
          width: 160px;
          height: 38px;
          margin: 0 20px 0 0;
          border-radius: 5px;
          border: solid 1px #fff;
          background: inherit;
          color: #fff;
        }
      }
    }

    > div:first-child {
      color: #ffdb00;
      > div:first-child {
        font-size: 18px;
        font-weight: 500;
      }
      > div:last-child {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .booking-card-lcl {
    background: #01a3a4 !important;
  }
}
.fw-normal {
  font-weight: normal !important;
}
.fw-bold {
  font-weight: 500 !important;
}

