.Step-One-Content {
  .Services.block.list-view .Services-Icon img, .Services-Block .Services-Icon img {
    height: 27px;
  }
  .Vehicles-List {
    &:hover {
      .Explain-Popup-choose-type {
        opacity: 1;
      }
    }
    .Explain-Popup-choose-type {
      display: inline-block;
      position: absolute;
      width: 340px;
      height: 85px;
      padding: 10px 20px 15px 20px;
      background-color: #41ad29;
      right: -397px;
      top: 50px;
      opacity: 0;
      overflow-wrap: break-word;
      color: var(--color-white);
      border-radius: var(--radius);
      .Choose-type-title {
        margin-bottom: 10px;
        font-size: 16px;
        font-weight: bold;
      }
      .Choose-type-content {
        margin-bottom: 5px;
        font-size: 13px;
        font-weight: 500;
        line-height: 1.4;
      }
      &:hover { 
        opacity: 0;
      }
    }
  }
  .Tab-List {
    .block-item {
      &:disabled {
        label {
          cursor: not-allowed !important;
        }
        cursor: not-allowed;
        background-color: #ccc;
        &:hover {
          background-color: #ccc !important;
        }
      }
    }
  }
  .Partial-Load {
    .Date-Picker {
      &:hover {
        .Explain-Popup {
          display: inline-block;
          z-index: 10;
          opacity: 1,
        }
      }
      background-color: var(--color-white);
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      .notify-calendar {
        background-color: var(--color-dark-green);
        color: var(--color-white);
        text-align: center;
        padding: 3px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      .rdtPicker{
        margin: 20px auto;
        background-color: var(--color-white);
        color: var(--color-black);
        // td.rdtNew,
        td.rdtOld, td.rdtDisabled, td.rdtDay.rdtDisabled:hover {
          color: #999;
          background-color: unset
        }
        td.rdtActive, td.rdtDay:hover {
          background-color: #027400;
          color: var(--color-white);
          border-radius: unset;
        }
      }
      .Explain-Popup {
        display: inline-block;
        position: absolute;
        width: 340px;
        height: 115px;
        right: -397px;
        top: 250px;
        opacity: 0;
        background-color: var(--color-green);
        padding: 10px 20px 15px 20px;
        overflow-wrap: break-word;
        color: var(--color-white);
        border-radius: var(--radius);
        .PickupTime-Image {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          b {
            padding-left: 7px;
          }
        }
        .PickupTime-Explanation {
          margin-bottom: 5px;
          font-size: 13px;
          font-weight: 500;
          line-height: 1.4;
        }
        .pickupTime-explanation-link {
          a {
            color: lightgreen;
            text-decoration: underline;
          }
        }
        &:hover { 
          opacity: 0;
        }
      }
    }
  }
}